import Navbar from "./components7/Navbar";
import Text from "./components7/Text";
import Footer from "./components3/Footer";

function NewApp() {
    return (
      <>
      <div> 

        <Navbar/>

        <Text/>
            
        <Footer/>

      </div>
      </>
    );
  }
  
  export default NewApp;