import Buduciprojekti from "./components3/Buduciprojekti";
import Footer from "./components3/Footer";
import Herothird from "./components3/Herothird";
import Tekuciproj from "./components3/Tekuciproj";
import Zelitepomoc from "./components3/Zelitepomoc";
import Text from "./components6/Text";
import Text2 from "./components6/Text2";


function NewApp() {
    return (
      <>
      <div>
        
        <Herothird/>
        <Text/>
        <Text2/>
        <Buduciprojekti/>
        <Zelitepomoc/>
        <Footer/>
        
      </div>
      </>
    );
  }
  
  export default NewApp;