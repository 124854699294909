
import Footer from "./components3/Footer";
import Kontakt from "./components5/Kontakt";
import Proba from "./components5/Proba";
import Proba2 from "./components5/Proba2";


function NewApp() {
    return (
      <>
      <div>
        
        <Kontakt/>
        
        <Footer/>
        

        
        
      </div>
      </>
    );
  }
  
  export default NewApp;